import { Badge, Box, Button, Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PageHead from "../../../components/admin/PageHead";
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import ActivityLogDialog from "./activityLogDialog";
import { LetterDetailsService } from "../../../utils/services/manage.service";
const ViewLetter = () => {
    // const location = useLocation();
    // const query = new URLSearchParams(location.search);
    // const prefillData = query.get('data') ? JSON.parse(decodeURIComponent(query.get('data')!)) : null;
    // const action = query.get('action');
    const navigate = useNavigate();
    const [manageToActivity, setManageToActivity] = useState<any>(null);
    const [activityDialogOpen, setActivityDialogOpen] = useState<boolean>(false);
    const handleOpenActivityDialog = (manage: any) => {
        setManageToActivity(manage);
        setActivityDialogOpen(true);
    };
    const handleCloseActivityDialog = () => {
        setActivityDialogOpen(false);
    };    
    const handleSuccess = () => {
       
    };
    const location = useLocation();
    const [viewLetter, setViewLetter] = useState([]);
    const getQueryParams = (search: string) => {
        return new URLSearchParams(search);
    };
    useEffect(() => {
        const queryParams = getQueryParams(location.search);
        const districtId = parseInt(queryParams.get('district_id') || '0');
        const letterId = parseInt(queryParams.get('letter_id') || '0');
        const fetchLetterDetails = async () => {
            const data = await LetterDetailsService({ district_id: districtId, letter_id: letterId });
            if (data) {
                setViewLetter(data.data);
            } else {
                console.error('Failed to fetch data:', data);
            }
        };
        fetchLetterDetails();
    }, [location.search]);
    const backTolist =() => {
        navigate('/manage')
    }
   return(
    
    <> 
        {viewLetter.length > 0 ? (
        viewLetter.map((row:any) => (
            <>
                <Box sx={{ px: 2, py: 1.5, display:'flex', justifyContent:'space-between' }}>
                    <PageHead primary='View Letter' />
                    <Box>
                        <Button  variant="contained" color="success" title="Activity Logs" onClick={() => handleOpenActivityDialog(row)} style={{ cursor: 'pointer', marginRight:10 }}>
                                Activity Log
                        </Button>        
                        <Button sx={{ml:3}} variant='contained' color='info' onClick={backTolist}>
                            Back
                        </Button>     
                    </Box>
                </Box>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} lg={5}>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >State Name:</TableCell>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{row.letters.state_name}</TableCell>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Company Name:</TableCell>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{row.letters.company_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Region Name:</TableCell>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{row.letters.region_name}</TableCell>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >District Name:</TableCell>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{row.letters.district_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{p:'0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Taluka Name:</TableCell>
                                        <TableCell sx={{p:'0', width:'25%', border:0}}>{row.letters.taluka_name}</TableCell>
                                        <TableCell sx={{p:'0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Project Name:</TableCell>
                                        <TableCell sx={{p:'0', width:'25%', border:0}}>{row.letters.village_name}</TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Addressee:</TableCell>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{row.letters.address}</TableCell>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >CC:</TableCell>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{row.letters.cc}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Category Name:</TableCell>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>
                                            <Box sx={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor:'pointer' }}>
                                                <Tooltip title={row.letters.category_name}>
                                                    {row.letters.category_name}
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Reference Number:</TableCell>
                                        <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{row.letters.reference_number || '-'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{p:'0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Created Date:</TableCell>
                                        <TableCell sx={{p:'0', width:'25%', border:0}}>{dayjs(row.letters.created_at || '-').format('DD-MM-YYYY ')}</TableCell>
                                        <TableCell sx={{p:'0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Subject:</TableCell>
                                        <TableCell sx={{p:'0', width:'25%', border:0}}>
                                            <Box sx={{ maxWidth: '350px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor:'pointer' }}>
                                                <Tooltip title={row.letters.subject}>
                                                    {row.letters.subject}
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                
                            </CardContent>           
                        </Card>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Card sx={{ mb: 2 }}>
                        <CardContent sx={{padding:0}}>
                            <TableContainer>
                                <Table>
                                    <TableHead sx={{ backgroundColor: '#F8FAFF', fontWeight: 'bold' }}>
                                        <TableRow>
                                            <TableCell width={'200px'}>Letter Stage</TableCell>
                                            <TableCell width={'200px'}>Upload File</TableCell>
                                            <TableCell width={'300px'}>Remark</TableCell>
                                            <TableCell width={'200px'}>Date</TableCell>                                       
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row?.letter_files?.map((file:any) => (
                                            <TableRow key={file.id}>
                                                <TableCell >{file.type_name}</TableCell>
                                                <TableCell>
                                                    <Button variant="outlined" color="primary" onClick={() => window.open(file.file_path, '_blank', 'noopener,noreferrer')} >
                                                        {file.file_name} <DownloadIcon sx={{ml:1}}/>
                                                    </Button>
                                                </TableCell>
                                                <TableCell>{file.remarks}</TableCell>
                                                <TableCell> 
                                                    {file.date ? new Date(file.date).toLocaleDateString('en-GB', {
                                                            day: '2-digit',  month: '2-digit', year: 'numeric', })
                                                        : '-'}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>  
            </>
            ))
            ) : (
                <Typography variant="body2" color="textSecondary">
                    No records found.
                </Typography>
            )}
           
        <ActivityLogDialog dialogOpen={activityDialogOpen} manageToActivity={manageToActivity} handleClose={handleCloseActivityDialog} handleSuccess={handleSuccess}/>  
    </>
   )
}
export default ViewLetter 