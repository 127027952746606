import ClientsAxios from '../client-axios';
import { ResponseEntity } from '../dto/response';

const { API_SERVER } = ClientsAxios;

export const ManageListService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('letters/list', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.body ? response.data.body : {});
    }
    return responseEntity;
}
export const StoreUploadLetterService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('letters/store', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.message ? response.data.message : {})
    }
    return responseEntity
}
export const FilterDataCreateService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('store/filter', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.message ? response.data.message : {})
    }
    return responseEntity
}

export const ActivityLogService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('letters/activity_log', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.body ? response.data.body : {});
    }
    return responseEntity;
}

export const BlockUnblockService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('letters/block_unblock',  payload )
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.StatusCode || 500, message, data);
    }
    return responseEntity
}
export const CancelRevertBackService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('letters/cancel',  payload )
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.StatusCode || 500, message, data);
    }
    return responseEntity
}

export const LetterDetailsService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('letters/details',  payload )
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.StatusCode || 500, message, data);
    }
    return responseEntity
}
export const LetterParentDetailsService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('letters/parent_details',  payload )
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.StatusCode || 500, message, data);
    }
    return responseEntity
}
export const LetterExportService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('letters/letter-export', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.body ? response.data.body : {});
    }
    return responseEntity;
}
export const DistrictExportService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('letters/district-export', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.body ? response.data.body : {});
    }
    return responseEntity;
}
export const ProjectExportService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('letters/project-export', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.body ? response.data.body : {});
    }
    return responseEntity;
}
export const SummaryExportService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('letters/summary-export', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.body ? response.data.body : {});
    }
    return responseEntity;
}
export const StatusChangeService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('letters/status-update', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.message ? response.data.message : {})
    }
    return responseEntity
}

export const StoreNotificationService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('notification/add_remark', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.message ? response.data.message : {})
    }
    return responseEntity;
}
export const NotificationLogService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('notification/notification_list', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.body ? response.data.body : {});
    }
    return responseEntity;
}
export const NotificationStatusService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('notification/notification_status', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.body ? response.data.body : {});
    }
    return responseEntity;
}
